import auth from '../reducers/authReducer';
import currentLesson from '../reducers/currentLessonPathReducer';
import dashboard from '../reducers/dashboardReducer.js';
import lesson from '../reducers/lessonReducer';
import list from '../reducers/listReducer.js';
import loader from '../reducers/loaderReducer';
import role from '../reducers/roleReducer';
import router from '../reducers/routerReducer';
import session from '../reducers/sessionReducer';
import student from '../reducers/studentReducer';
import stuff from '../reducers/stuffReducer';

const reducers = {
	auth,
	router,
	role,
	stuff,
	student,
	session,
	lesson,
	loader,
	list,
	dashboard,
	currentLesson,
};

export default reducers;
