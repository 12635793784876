import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import Loader from '../components/Loader';
import store from '../store/store';
import { LoaderProvider } from './LoaderContainer';
import RootContainer from './RootContainer';
import { ThemeContainer } from './ThemeContainer';

export function AppContainer() {
	const queryClient = new QueryClient({
		defaultOptions: {
			queries: {
				refetchOnMount: false,
				refetchInterval: false,
				refetchOnWindowFocus: false,
				refetchIntervalInBackground: false,
			},
		},
	});

	return (
		<QueryClientProvider client={queryClient}>
			<Provider store={store}>
				<BrowserRouter>
					<Loader />
					<LoaderProvider>
						<ThemeContainer>
							<RootContainer />
						</ThemeContainer>
					</LoaderProvider>
				</BrowserRouter>
			</Provider>
			<ReactQueryDevtools initialIsOpen={false} />
		</QueryClientProvider>
	);
}
