export const GET_STUFF_LIST_START = 'GET_STUFF_LIST_START';
export const GET_STUFF_LIST_SUCCESS = 'GET_STUFF_LIST_SUCCESS';
export const GET_STUFF_LIST_ERROR = 'GET_STUFF_LIST_ERROR';
export const SELECTED_STUFF_CLASS = 'SELECTED_STUFF_CLASS';
export const SELECTED_STUFF_ELEMENT = 'SELECTED_STUFF_ELEMENT';
export const SET_FORM_FIELD_VALUE = 'SET_FORM_FIELD_VALUE';
export const RESET_FORM_FIELDS = 'RESET_FORM_FIELDS';
export const SET_FORM_FIELDS = 'SET_FORM_FIELDS';
export const DELETE_CHECKED_CLASS = 'DELETE_CHECKED_CLASS';
export const DELETE_CHECKED_ELEMENT = 'DELETE_CHECKED_ELEMENT';
export const GET_NATIONALITIES_START = 'GET_NATIONALITIES_START';
export const GET_NATIONALITIES_SUCCESS = 'GET_NATIONALITIES_SUCCESS';
export const GET_NATIONALITIES_ERROR = 'GET_NATIONALITIES_ERROR';
export const GET_GENDERS_START = 'GET_GENDERS_START';
export const GET_GENDERS_SUCCESS = 'GET_GENDERS_SUCCESS';
export const GET_GENDERS_ERROR = 'GET_GENDERS_ERROR';
export const GET_SUBJECT_BY_ROOM_ID_SUCCESS = 'GET_SUBJECT_BY_ROOM_ID_SUCCESS';
