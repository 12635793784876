const Routes = {
	Login: '/login',

	Teacher: '/teacher',

	TeacherHome: '/teacher/home',

	Dashboard: '/dashboard',

	Contact: '/contact',
	ContactHome: '/contact/home',
	ContactSchedule: '/contact/schedule',

	Student: '/student',
	StudentHome: '/student/home',
	StudentSchedule: '/student/schedule',

	StuffList: '/dashboard/stuff/list',
	StuffItem: '/dashboard/stuff/:id/:tab',
	StuffProfile: '/dashboard/stuff/profile/',

	Home: '/dashboard/home',
	StudentList: '/dashboard/students/list',
	MoveStudents: '/dashboard/move-students',
	StudentAddEdit: '/dashboard/student/add-edit',
	ContactList: '/dashboard/contacts/list',
	ContactAddEdit: '/dashboard/contact/add-edit',
	Lessons: '/dashboard/lessons',
	Events: '/dashboard/events',
	Accounting: '/dashboard/accounting',
	Planner: '/dashboard/planner',
	StudyLoad: '/dashboard/studyLoad',
	Calendar: '/dashboard/calendar',
	HistoryChanges: '/dashboard/history-changes',
	Components: '/dashboard/settings',
	RolesEdit: '/dashboard/settings/roles-edit/:id',
	AnnualSubjectEdit: '/dashboard/lessons/annual-subject-edit-panel',
	StudentsEditPanel: '/dashboard/students/:id/students-edit-panel/:tab',
	StudentsHomeworkEditList: '/dashboard/students/students-homework/students-homework-edit-panel',
	Chat: '/dashboard/chat',
	List: '/dashboard/sciences',
	Schools: '/dashboard/schools',
	Class: '/dashboard/classes',
	Groups: '/dashboard/groups',
	Room: '/dashboard/rooms',

	ScheduleItem: '/dashboard/schedule/item/:id/:tab',
	ScheduleList: '/dashboard/schedule/list',

	ClassRegister: '/dashboard/class-register',
	ClassRegisterCalendar: '/dashboard/class-register/:classNumber',

	StudyPlan: '/dashboard/study-plan',
	Reports: '/dashboard/reports',

	ThematicPlan: '/dashboard/thematic-plan',
	ThematicPlanList: '/dashboard/thematic-plan/:classNumber',

	Archive: '/dashboard/archive/list',

	ProgressTracking: '/dashboard/progress-tracking',
};

export default Routes;
