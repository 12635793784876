export const SET_ADD_STUDENTS_PARENT_CONTACT_MODAL_VISIBLE =
	'SET_ADD_STUDENTS_PARENT_CONTACT_MODAL_VISIBLE';
export const SAVE_STUDENTS_PARENT_CONTACT = 'SAVE_STUDENTS_PARENT_CONTACT';
export const DELETING_PARENT_CONTACT_MODAL_VISIBLE = 'DELETING_PARENT_CONTACT_MODAL_VISIBLE';
export const SET_DELETING_PARENT_CONTACT = 'SET_DELETING_PARENT_CONTACT';
export const DELETE_PARENT_CONTACT = 'DELETE_PARENT_CONTACT';
export const SET_EDIT_VACCINE_MODAL_VISIBLE = 'SET_EDIT_VACCINE_MODAL_VISIBLE';
export const SAVE_EDITED_VACCINE = 'SAVE_EDITED_VACCINE';
export const EDIT_PARENT_CONTACT_MODAL_VISIBLE = 'EDIT_PARENT_CONTACT_MODAL_VISIBLE';
export const SET_EDIT_MODE_PARENT_CONTACT = 'SET_EDIT_MODE_PARENT_CONTACT';
export const SET_EDIT_PARENT_CONTACT = 'SET_EDIT_PARENT_CONTACT';
export const ADD_MEDICAL_LIST_MODAL_VISIBLE = 'ADD_MEDICAL_LIST_MODAL_VISIBLE';
export const SAVE_USER_TO_MEDICAL_LIST = 'SAVE_USER_TO_MEDICAL_LIST';
export const DELETE_VACCINE_LIST_MODAL_VISIBLE = 'DELETE_VACCINE_LIST_MODAL_VISIBLE';
export const SET_VACCINE_TO_DELETE = 'SET_VACCINE_TO_DELETE';
export const DELETE_VACCINE = 'DELETE_VACCINE';
export const STUDENTS_TRAINING_MODAL_VISIBLE = 'STUDENTS_TRAINING_MODAL_VISIBLE';
export const ADD_STUDENTS_TRAINING_MODAL_VISIBLE = 'ADD_STUDENTS_TRAINING_MODAL_VISIBLE';
export const SET_STUDENT_TRAINING_COURSE_TO_DELETE = 'SET_STUDENT_TRAINING_COURSE_TO_DELETE';
export const DELETE_TRAINING_COURSE = 'DELETE_TRAINING_COURSE';
export const STUDENTS_TRAINING_FORM_VISIBLE = 'STUDENTS_TRAINING_FORM_VISIBLE';
export const SAVE_EDITED_TRAINING_COURSE = 'SAVE_EDITED_TRAINING_COURSE';
export const SAVE_NEW_TRAINING_COURSE = 'SAVE_NEW_TRAINING_COURSE';

export const STUDENTS_LIST_ITEM_SHOW_MODAL = 'STUDENTS_LIST_ITEM_SHOW_MODAL';
export const STUDENTS_LIST_ITEM_DELETE = 'STUDENTS_LIST_ITEM_DELETE';
export const SET_STUDENTS_LIST_ITEM_DELETING = 'SET_STUDENTS_LIST_ITEM_DELETING';
export const STUDENTS_LIST_ITEM_MODAL_VISIBLE = 'STUDENTS_LIST_ITEM_MODAL_VISIBLE';
export const SET_STUDENTS_LIST_ITEM_EDIT = 'STUDENTS_LIST_ITEM_MODAL_VISIBLE';
export const STUDENTS_LIST_ITEM_EDIT = 'STUDENTS_LIST_ITEM_EDIT';
export const STUDENTS_LIST_ITEM_ADD_MODAL_VISIBLE = 'STUDENTS_LIST_ITEM_ADD_MODAL_VISIBLE';
export const STUDENTS_LIST_ITEM_SAVE_SCIENCE = 'STUDENTS_LIST_ITEM_SAVE_SCIENCE';
export const GET_STUDENT_LIST_START = 'GET_STUDENT_LIST_START';
export const GET_STUDENT_LIST_SUCCESS = 'GET_STUDENT_LIST_SUCCESS';
export const GET_STUDENT_LIST_ERROR = 'GET_STUDENT_LIST_ERROR';
export const SAVE_STUDENT_START = 'SAVE_STUDENT_START';
export const SAVE_STUDENT_SUCCESS = 'SAVE_STUDENT_SUCCESS';
export const SAVE_STUDENT_ERROR = 'SAVE_STUDENT_ERROR';
export const GET_STUDENT_START = 'GET_STUDENT_START';
export const GET_STUDENT_SUCCESS = 'GET_STUDENT_SUCCESS';
export const GET_STUDENT_ERROR = 'GET_STUDENT_ERROR';
export const DELETE_STUDENT_START = 'DELETE_STUDENT_START';
export const DELETE_STUDENT_SUCCESS = 'DELETE_STUDENT_SUCCESS';
export const DELETE_STUDENT_ERROR = 'DELETE_STUDENT_ERROR';
export const RESET_FORM_FIELDS = 'RESET_FORM_FIELDS';
export const SET_FORM_FIELDS = 'SET_FORM_FIELDS';
export const SET_FORM_FIELD_VALUE = 'SET_FORM_FIELD_VALUE';
export const SET_SELECTED_STUDENT_FROM_ARCHIVE = 'SET_SELECTED_STUDENT_FROM_ARCHIVE';
