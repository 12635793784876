import {
	DELETE_CHECKED_CLASS,
	DELETE_CHECKED_ELEMENT,
	GET_STUFF_LIST_SUCCESS,
	GET_SUBJECT_BY_ROOM_ID_SUCCESS,
	RESET_FORM_FIELDS,
	SELECTED_STUFF_CLASS,
	SELECTED_STUFF_ELEMENT,
	SET_FORM_FIELD_VALUE,
	SET_FORM_FIELDS,
} from '../actionTypes/stuffActionTypes';
import createReducer from '../helpers/createReducer';

const defaultState = {
	stuffList: [],
	classList: [
		{ id: 1, class: '1А' },
		{ id: 2, class: '1Б' },
		{ id: 3, class: '1В' },
		{ id: 4, class: '2А' },
		{ id: 5, class: '2Б' },
		{ id: 6, class: '2В' },
		{ id: 7, class: '3А' },
		{ id: 8, class: '3Б' },
		{ id: 9, class: '3В' },
	],

	elements: [
		{ id: 1, science: 'Математика' },
		{ id: 2, science: 'Чистописание' },
		{ id: 3, science: 'Чтение' },
		{ id: 4, science: 'Труд' },
		{ id: 5, science: 'Природоведение' },
		{ id: 6, science: 'Природоведение' },
		{ id: 7, science: 'Труд' },
		{ id: 8, science: 'Природоведение' },
		{ id: 9, science: 'Природоведение' },
	],
	initialValues: {
		status: 'INVITED',
		mobile: '',
		phone: '',
		email: '',
		avatar: null,
		first_name: '',
		last_name: '',
		surname: '',
		nationality: null,
		birthday: '',
		gender: null,
		education: [],
		position: '',
		category: [],
		classroomTeacher: [],
		speciality: [],
		experience: null,
		employment: [],
		street1: '',
		street2: '',
		payroll: null,
		employment_notes: '',
		personal_notes: '',
		rooms: [],
		subjects: [],
	},
	item: {},
	subjectsByRoom: {},
	checkedClasses: {},
	checkedElements: {},
	nationality: [],
	gender: [],
	count: 0,
	nativeLang: [],
	english: [],
	theWorldHistory: [],
	math: [],
};
const reducers = {
	[GET_STUFF_LIST_SUCCESS](state, { data }) {
		return {
			...state,
			stuffList: data.results || [],
			count: data.count || 0,
		};
	},
	[SET_FORM_FIELD_VALUE](state, { field, value }) {
		return {
			...state,
			initialValues: {
				...state.initialValues,
				[field]: value,
			},
		};
	},
	[SET_FORM_FIELDS](state, { values }) {
		return {
			...state,
			initialValues: {
				...state.initialValues,
				...values,
			},
			item: values,
		};
	},
	[RESET_FORM_FIELDS](state) {
		return {
			...state,
			initialValues: {
				status: 'INVITED',
				mobile: '',
				phone: '',
				email: '',
				avatar: null,
				first_name: '',
				last_name: '',
				surname: '',
				nationality: null,
				birthday: '',
				gender: null,
				street1: '',
				street2: '',
				payroll: null,
				employment_notes: '',
				personal_notes: '',
				rooms: [],
				subjects: [],
			},
			item: {},
		};
	},
	[SELECTED_STUFF_CLASS](state, { data, checked }) {
		let _checkedClasses = { ...state.checkedClasses, [data.id]: data };
		if (!checked) {
			delete _checkedClasses[data.id];
		}
		return {
			...state,
			checkedClasses: _checkedClasses,
		};
	},
	[DELETE_CHECKED_CLASS](state, { data }) {
		let _checkedClasses = { ...state.checkedClasses };
		delete _checkedClasses[data.id];
		return {
			...state,
			checkedClasses: _checkedClasses,
		};
	},
	[GET_SUBJECT_BY_ROOM_ID_SUCCESS](state, { data, request }) {
		const { key } = request;
		return {
			...state,
			subjectsByRoom: {
				...state.subjectsByRoom,
				[key]: (data.results || []).map((item) => ({ value: item.id, label: item.name })),
			},
		};
	},
	[SELECTED_STUFF_ELEMENT](state, { data, checked }) {
		let _checkedElements = { ...state.checkedElements, [data.id]: data };
		if (!checked) {
			delete _checkedElements[data.id];
		}
		return {
			...state,
			checkedElements: _checkedElements,
		};
	},
	[DELETE_CHECKED_ELEMENT](state, { data }) {
		let _checkedElements = { ...state.checkedElements };
		delete _checkedElements[data.id];
		return {
			...state,
			checkedElements: _checkedElements,
		};
	},
};
export default createReducer(defaultState, reducers);
