export const START_LOADER = 'START_LOADER';
export const STOP_LOADER = 'STOP_LOADER';
export const SHOW_MESSAGE = 'SHOW_MESSAGE';
export const EMPTY_MESSAGES = 'EMPTY_MESSAGES';

export function startLoader() {
	return {
		type: START_LOADER,
	};
}

export function stopLoader(info) {
	return {
		type: STOP_LOADER,
		info,
	};
}

export function showMessage(info) {
	return {
		type: SHOW_MESSAGE,
		info,
	};
}

export function closeModal() {
	return {
		type: EMPTY_MESSAGES,
	};
}
