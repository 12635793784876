import { httpClient } from './BaseApi';
export const TOKEN_URL = 'api/token/';
const DefaultData = {
	access: '',
	refresh: '',
};
export { DefaultData };

const AuthApi = {
	login: (data) => httpClient.post(TOKEN_URL, { data }),
};
export default AuthApi;
