import { applyMiddleware, combineReducers, createStore } from 'redux';
import logger from 'redux-logger';
import thunkMiddleware from 'redux-thunk';
import { IS_DEV } from '../constants/AppConstants';

import apiMiddleware from '../middlewares/apiMiddleware';
import reducers from './reducers';

export default createStore(
	combineReducers({
		...reducers,
	}),
	applyMiddleware(...[apiMiddleware, thunkMiddleware, IS_DEV && logger].filter(Boolean)),
);
