import {
	ADD_CLASS_MODAL_VISIBLE,
	ADD_MODAL_VISIBLE,
	ADD_ROOM_MODAL_VISIBLE,
	GET_CLASS_SUBJECTS_SUCCESS,
	GET_LIST_CLASSES_SUCCESS,
	GET_ROOM_SUBJECTS_SUCCESS,
	SET_FORM_FIELD_VALUE,
} from '../actionTypes/classListActionTypes';
import createReducer from '../helpers/createReducer';

const defaultState = {
	rooms: [
		{
			id: 1,
			name: 'Столовая',
			floor: '1',
			createdDate: '09.10.2021',
		},
		{
			id: 2,
			name: 'Спортзал',
			floor: '1',
			createdDate: '09.10.2021',
		},
		{
			id: 3,
			name: '17',
			floor: '1/1',
			createdDate: '09.10.2021',
		},
		{
			id: 4,
			name: '18',
			floor: '1/1',
			createdDate: '09.10.2021',
		},
		{
			id: 5,
			name: '19',
			floor: '1/1',
			createdDate: '09.10.2021',
		},
		{
			id: 6,
			name: 'Учительская',
			floor: '1/1',
			createdDate: '09.10.2021',
		},
		{
			id: 7,
			name: '21',
			floor: '2/1',
			createdDate: '09.10.2021',
		},
		{
			id: 8,
			name: '22',
			floor: '2/1',
			createdDate: '09.10.2021',
		},
		{
			id: 9,
			name: '23',
			floor: '2/1',
			createdDate: '09.10.2021',
		},
	],
	addModalVisible: false,
	addClassModalVisible: false,
	addRoomModalVisible: false,
	item: {},
	listClasses: [],
	listClassesCount: 0,
	classSubjectList: [],
	classSubjectCount: 0,
	roomSubjectList: [],
	roomSubjectCount: 0,
};

const reducers = {
	[ADD_MODAL_VISIBLE](state) {
		return {
			...state,
			addModalVisible: !state.addModalVisible,
		};
	},
	[ADD_CLASS_MODAL_VISIBLE](state) {
		return {
			...state,
			addClassModalVisible: !state.addClassModalVisible,
		};
	},
	[ADD_ROOM_MODAL_VISIBLE](state) {
		return {
			...state,
			addRoomModalVisible: !state.addRoomModalVisible,
		};
	},
	[GET_CLASS_SUBJECTS_SUCCESS](state, { data }) {
		return {
			...state,
			classSubjectList: data.results,
			classSubjectCount: data.count,
		};
	},
	[GET_ROOM_SUBJECTS_SUCCESS](state, { data }) {
		return {
			...state,
			roomSubjectList: data.results,
			roomSubjectCount: data.count,
		};
	},
	[GET_LIST_CLASSES_SUCCESS](state, { data }) {
		return {
			...state,
			listClasses: data.results,
			listClassesCount: data.count,
		};
	},
	[SET_FORM_FIELD_VALUE](state, { field, value }) {
		return {
			...state,
			initialValues: {
				...state.initialValues,
				[field]: value,
			},
		};
	},
};

export default createReducer(defaultState, reducers);
