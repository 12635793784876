import {
	END_LESSON,
	OPEN_THEMES_SIDEBAR,
	START_LESSON,
} from '../actionTypes/currentLessonPathTpes';
import createReducer from '../helpers/createReducer';

const defaultState = {
	lessonPath: '',
	sidebarVisible: false,
};

const reducers = {
	[START_LESSON](state, { lessonPath }) {
		localStorage.setItem('lessonPath', lessonPath);
		return {
			...state,
			lessonPath,
		};
	},
	[END_LESSON](state) {
		localStorage.removeItem('lessonPath');
		return {
			lessonPath: '',
		};
	},
	[OPEN_THEMES_SIDEBAR](state, { toggle }) {
		return {
			...state,
			sidebarVisible: toggle,
		};
	},
};

export default createReducer(defaultState, reducers);
