import {
	ADD_MEDICAL_LIST_MODAL_VISIBLE,
	ADD_STUDENTS_TRAINING_MODAL_VISIBLE,
	DELETE_PARENT_CONTACT,
	DELETE_TRAINING_COURSE,
	DELETE_VACCINE,
	DELETE_VACCINE_LIST_MODAL_VISIBLE,
	DELETING_PARENT_CONTACT_MODAL_VISIBLE,
	EDIT_PARENT_CONTACT_MODAL_VISIBLE,
	GET_STUDENT_LIST_SUCCESS,
	RESET_FORM_FIELDS,
	SAVE_EDITED_TRAINING_COURSE,
	SAVE_EDITED_VACCINE,
	SAVE_NEW_TRAINING_COURSE,
	SAVE_STUDENTS_PARENT_CONTACT,
	SAVE_USER_TO_MEDICAL_LIST,
	SET_ADD_STUDENTS_PARENT_CONTACT_MODAL_VISIBLE,
	SET_DELETING_PARENT_CONTACT,
	SET_EDIT_MODE_PARENT_CONTACT,
	SET_EDIT_PARENT_CONTACT,
	SET_EDIT_VACCINE_MODAL_VISIBLE,
	SET_FORM_FIELDS,
	SET_SELECTED_STUDENT_FROM_ARCHIVE,
	SET_STUDENT_TRAINING_COURSE_TO_DELETE,
	SET_STUDENTS_LIST_ITEM_DELETING,
	SET_STUDENTS_LIST_ITEM_EDIT,
	SET_VACCINE_TO_DELETE,
	STUDENTS_LIST_ITEM_ADD_MODAL_VISIBLE,
	STUDENTS_LIST_ITEM_DELETE,
	STUDENTS_LIST_ITEM_EDIT,
	STUDENTS_LIST_ITEM_SAVE_SCIENCE,
	STUDENTS_LIST_ITEM_SHOW_MODAL,
	STUDENTS_TRAINING_FORM_VISIBLE,
	STUDENTS_TRAINING_MODAL_VISIBLE,
} from '../actionTypes/studentActionTypes';
import createReducer from '../helpers/createReducer';

const defaultState = {
	studentList: [],
	parents: [
		{
			id: 1,
			name: 'Василий Чернов',
			position: 'Папа',
			phone: '+998 99 888 88 88',
			email: 'Vasiliy.Chernov@gmail.com',
			permit: 'Зарегистрировано',
			status: 'Разрешается',
		},
		{
			id: 2,
			name: 'Виктория Чернова',
			position: 'Мама',
			phone: '+998 99 888 88 88',
			email: 'Victoria.Chehrnova@gmail.com',
			permit: 'Зарегистрировано',
			status: 'Разрешается',
		},
		{
			id: 3,
			name: 'Василий Чернов',
			position: 'Папа',
			phone: '+998 99 888 88 88',
			email: 'Vasiliy.Chernov@gmail.com',
			permit: 'Зарегистрировано',
			status: 'Разрешается',
		},
	],
	medicalList: [
		{
			name: 'Доктор. Светлана Николаевна',
			phoneNumber: '+998 90 909 09 09',
		},
	],
	vaccinationData: [
		{
			id: 1,
			vaccine: 'ОПВ - 5',
			description: 'Вакцина от полиомиелита',
			date: '10.10.2012',
			status: true,
		},
		{
			id: 2,
			vaccine: 'АДС - М-5 ',
			description: 'Вакцина от дифтерии и столбняка',
			date: '10.10.2012',
			status: true,
		},
		{
			id: 3,
			vaccine: 'ВПЧ - 5',
			description: 'Вакцина от папилломы',
			date: '10.10.2012',
			status: true,
		},
		{
			id: 4,
			vaccine: 'АДС - М-6',
			description: 'Вакцина от дифтерии и столбняка',
			date: '10.10.2012',
			status: false,
		},
	],
	studentsTraining: [
		{
			id: 1,
			course: 'Шахматы',
			day: 'Сб',
			time: '15:00',
			teacher: 'А.В Мария',
		},
		{
			id: 2,
			course: 'Рисование',
			day: 'Вс',
			time: '15:00',
			teacher: 'А.В Мария',
		},
		{
			id: 3,
			course: 'Программирование',
			day: 'Пн, Вт',
			time: '15:00',
			teacher: 'А.В Мария',
		},
		{
			id: 4,
			course: 'Математика',
			day: 'Вт',
			time: '15:00',
			teacher: 'А.В Мария',
		},
	],
	scienceList: [
		{
			id: 1,
			science: 'Английский',
			category: 'Social & Emotional',
			description: 'Description text will be here',
		},
		{
			id: 2,
			science: 'Алгебра',
			category: 'Social & Emotional',
			description: 'Description text will be here',
		},
		{
			id: 3,
			science: 'Биология',
			category: 'Social & Emotional',
			description: 'Description text will be here',
		},
		{
			id: 4,
			science: 'Физика',
			category: 'Social & Emotional',
			description: 'Description text will be here',
		},
		{
			id: 5,
			science: 'Геометрия',
			category: 'Social & Emotional',
			description: 'Description text will be here',
		},
		{
			id: 6,
			science: 'История',
			category: 'Social & Emotional',
			description: 'Description text will be here',
		},
		{
			id: 7,
			science: 'Химия',
			category: 'Social & Emotional',
			description: 'Description text will be here',
		},
		{
			id: 8,
			science: 'Английский',
			category: 'Social & Emotional',
			description: 'Description text will be here',
		},
		{
			id: 9,
			science: 'Химия',
			category: 'Social & Emotional',
			description: 'Description text will be here',
		},
		{
			id: 10,
			science: 'История',
			category: 'Social & Emotional',
			description: 'Description text will be here',
		},
	],
	medicalListVisible: false,
	editVaccineVisible: false,
	deleteVaccineVisible: false,
	StudentsTrainingVisible: false,
	studentsTrainingFormVisible: false,
	addStudentsTrainingCourseVisible: false,
	show: false,
	deleteModalVisible: false,
	editModalVisible: false,
	studentsListItemDeleteModal: false,
	studentsListItemEditModal: false,
	studentsListItemAdd: false,
	initialValues: {
		first_name: '',
		middle_name: '',
		last_name: '',
		birthday: '',
		billing_id: '',
		room: null,
		mobile: '',
		email: '',
		street1: '',
		street2: '',
		nationality: null,
		gender: '',
		avatar: null,
	},
	item: {},
	selectedStudentsFromArchive: [],
	idListToDelete: [],
	archiveFormList: [],
};
const reducers = {
	[GET_STUDENT_LIST_SUCCESS](state, { data }) {
		return {
			...state,
			studentList: data.results || [],
		};
	},
	[SET_FORM_FIELDS](state, { values }) {
		return {
			...state,
			initialValues: {
				...state.initialValues,
				...values,
			},
			item: values,
		};
	},
	[RESET_FORM_FIELDS](state) {
		return {
			...state,
			initialValues: {
				first_name: '',
				middle_name: '',
				last_name: '',
				birthday: '',
				billing_id: '',
				room: null,
				mobile: '',
				email: '',
				street1: '',
				street2: '',
				nationality: null,
				gender: '',
				avatar: null,
			},
			item: {},
			selectedStudentsFromArchive: [],
		};
	},
	[SET_ADD_STUDENTS_PARENT_CONTACT_MODAL_VISIBLE](state) {
		return {
			...state,
			show: !state.show,
		};
	},
	[SAVE_STUDENTS_PARENT_CONTACT](state, { data }) {
		const { parents } = state;
		return {
			...state,
			parents: [...parents, data],
		};
	},
	[DELETING_PARENT_CONTACT_MODAL_VISIBLE](state) {
		return {
			...state,
			deleteModalVisible: !state.deleteModalVisible,
		};
	},
	[SET_DELETING_PARENT_CONTACT](state, { item }) {
		return {
			...state,
			item,
		};
	},
	[DELETE_PARENT_CONTACT](state) {
		const parents = state.parents.filter((item) => item.id !== state.item.id);
		return {
			...state,
			parents,
			item: {},
			deleteModalVisible: false,
		};
	},
	[ADD_MEDICAL_LIST_MODAL_VISIBLE](state) {
		return {
			...state,
			medicalListVisible: !state.medicalListVisible,
		};
	},
	[SAVE_USER_TO_MEDICAL_LIST](state, { data }) {
		const { medicalList } = state;
		return {
			...state,
			medicalList: [...medicalList, data],
		};
	},
	[SET_EDIT_VACCINE_MODAL_VISIBLE](state, { item }) {
		if (!state.editVaccineVisible) {
			return {
				...state,
				item,
				editVaccineVisible: !state.editVaccineVisible,
			};
		}
		return {
			...state,
			editVaccineVisible: !state.editVaccineVisible,
			item: {},
		};
	},

	[SAVE_EDITED_VACCINE](state, { data }) {
		const { item } = state;
		const updated = state.vaccinationData.map((list) => {
			if (list.id === item.id) {
				list.date = data.date;
				list.status = data.status;
			}
			return list;
		});
		return {
			...state,
			vaccinationData: updated,
			item: {},
		};
	},
	[DELETE_VACCINE_LIST_MODAL_VISIBLE](state) {
		return {
			...state,
			deleteVaccineVisible: !state.deleteVaccineVisible,
		};
	},
	[SET_VACCINE_TO_DELETE](state, { item }) {
		return {
			...state,
			item,
		};
	},
	[DELETE_VACCINE](state) {
		const vaccinationData = state.vaccinationData.filter((item) => item.id !== state.item.id);
		return {
			...state,
			vaccinationData,
			item: {},
			deleteVaccineVisible: false,
		};
	},

	[EDIT_PARENT_CONTACT_MODAL_VISIBLE](state) {
		if (state.editModalVisible) {
			return {
				...state,
				editModalVisible: !state.editModalVisible,
				item: {},
			};
		}
		return {
			...state,
			editModalVisible: !state.editModalVisible,
		};
	},

	[SET_EDIT_MODE_PARENT_CONTACT](state, { data }) {
		return {
			...state,
			item: data,
		};
	},
	[SET_EDIT_PARENT_CONTACT](state, { data }) {
		const res = state.parents.map((parent) => {
			if (parent.id === state.item.id) {
				parent.name = data.name;
				parent.position = data.position;
				parent.phone = data.phone;
				parent.email = data.email;
				parent.status = data.status;
			}
			return parent;
		});
		return {
			...state,
			parents: res,
			item: {},
			editModalVisible: false,
		};
	},
	[STUDENTS_TRAINING_MODAL_VISIBLE](state) {
		return {
			...state,
			StudentsTrainingVisible: !state.StudentsTrainingVisible,
		};
	},
	[SET_STUDENT_TRAINING_COURSE_TO_DELETE](state, { item }) {
		return {
			...state,
			item,
		};
	},
	[DELETE_TRAINING_COURSE](state) {
		const filtered = state.studentsTraining.filter((item) => item.id !== state.item.id);
		return {
			...state,
			studentsTraining: filtered,
			item: {},
			StudentsTrainingVisible: false,
		};
	},
	[STUDENTS_TRAINING_FORM_VISIBLE](state, { item }) {
		return {
			...state,
			item,
			studentsTrainingFormVisible: !state.studentsTrainingFormVisible,
		};
	},
	[SAVE_EDITED_TRAINING_COURSE](state, { data }) {
		const { item } = state;
		const result = state.studentsTraining.map((list) => {
			if (list.id === item.id) {
				list.day = data.weeklyDays.map((days) => days.label);
				list.time = data.time;
				list.teacher = data.teachers.label;
			}
			return list;
		});
		return {
			...state,
			studentsTraining: result,
			studentsTrainingFormVisible: !state.studentsTrainingFormVisible,
			item: {},
		};
	},
	[ADD_STUDENTS_TRAINING_MODAL_VISIBLE](state) {
		return {
			...state,
			addStudentsTrainingCourseVisible: !state.addStudentsTrainingCourseVisible,
		};
	},
	[SAVE_NEW_TRAINING_COURSE](state, { data }) {
		const { studentsTraining } = state;
		return {
			...state,
			studentsTraining: [...studentsTraining, data],
			studentsTrainingFormVisible: false,
		};
	},
	[STUDENTS_LIST_ITEM_SHOW_MODAL](state) {
		return {
			...state,
			studentsListItemDeleteModal: !state.studentsListItemDeleteModal,
		};
	},

	[SET_STUDENTS_LIST_ITEM_DELETING](state, { item }) {
		return {
			...state,
			item,
		};
	},

	[STUDENTS_LIST_ITEM_DELETE](state) {
		const scienceList = state.scienceList.filter((item) => item.id !== state.item.id);
		return {
			...state,
			scienceList,
			item: {},
			studentsListItemDeleteModal: false,
		};
	},

	[SET_STUDENTS_LIST_ITEM_EDIT](state, { data }) {
		return {
			...state,
			item: data,
			studentsListItemEditModal: !state.studentsListItemEditModal,
		};
	},

	[STUDENTS_LIST_ITEM_EDIT](state, { data }) {
		const res = state.scienceList.map((science) => {
			if (science.id === state.item.id) {
				science.science = data.science;
				science.category = data.category;
				science.description = data.description;
			}
			return science;
		});
		return {
			...state,
			scienceList: res,
			item: {},
			studentsListItemEditModal: false,
		};
	},
	[STUDENTS_LIST_ITEM_ADD_MODAL_VISIBLE](state) {
		return {
			...state,
			studentsListItemAdd: !state.studentsListItemAdd,
		};
	},
	[STUDENTS_LIST_ITEM_SAVE_SCIENCE](state, { data }) {
		const { scienceList } = state;
		return {
			...state,
			scienceList: [...scienceList, data],
		};
	},
	[SET_SELECTED_STUDENT_FROM_ARCHIVE](state, { data, isSelected }) {
		const { selectedStudentsFromArchive } = state;

		if (!isSelected) {
			let _selectedStudentsFromArchive = selectedStudentsFromArchive.filter(
				(student) => student.id !== data.id,
			);

			return {
				...state,
				selectedStudentsFromArchive: _selectedStudentsFromArchive,
			};
		}

		return {
			...state,
			selectedStudentsFromArchive: [data],
			initialValues: data,
			item: data,
		};
	},
};
export default createReducer(defaultState, reducers);
