import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import ReactDOM from 'react-dom';
import './assets/styles/index.scss';

import { AppContainer } from './containers/AppContainer';

ReactDOM.render(
	<React.StrictMode>
		<AppContainer />
	</React.StrictMode>,
	document.getElementById('root'),
);
