import { CLEAR_GLOBAL_SEARCH, SET_GLOBAL_SEARCH } from '../actionTypes/dashboardActionsTypes';
import createReducer from '../helpers/createReducer';

const defaultState = {
	searchValue: '',
};
const reducers = {
	[SET_GLOBAL_SEARCH](state, { value }) {
		return {
			...state,
			searchValue: value,
		};
	},
	[CLEAR_GLOBAL_SEARCH](state) {
		return {
			...state,
			searchValue: '',
		};
	},
};

export const dashboardSearchSelector = ({ dashboard }) => dashboard.searchValue;

export default createReducer(defaultState, reducers);
