import { Button } from 'react-bootstrap';

function CButton(props) {
	return (
		<Button variant={props.variant || 'default'} {...props}>
			{props.children}
		</Button>
	);
}

export default CButton;
