import { GET_ACTIVE_URL, SET_ACTIVE_TAB } from '../actions/routerActions';
import createReducer from '../helpers/createReducer';

const defaultState = {
	activeUrl: '/',
	activeTab: '',
};
const reducers = {
	[GET_ACTIVE_URL](state, { url }) {
		return {
			...state,
			activeUrl: url,
		};
	},
	[SET_ACTIVE_TAB](state, { activeTab }) {
		return {
			...state,
			activeTab,
		};
	},
};
export default createReducer(defaultState, reducers);
