import { httpClient } from './BaseApi';
export const UPLOAD_IMAGE_URL = 'api/upload/';
export const ROOMS_URL = 'api/rooms/';
export const SUBJECT_URL = 'api/subjects/';
export const NATIONALITIES_URL = 'api/nationalities/';
export const GENDER_URL = 'api/student_students/genders/';
export const PAYROLLS_URL = 'api/payrolls/';
export const GET_APP_LOGO = 'api/schools/logo/';
export const GET_APP_STUDY_YEARS = 'api/study_years/';
export const GET_QUARTERS_LIST = 'api/quarters/';
export const GET_CURRENT_SCHOOL = 'api/school_schools/current/';
export const GET_MARKING_SYSTEMS = 'api/marks/marking_systems/';

const SessionApi = {
	upload: (data) => httpClient.post(UPLOAD_IMAGE_URL, { data }),
	getNationalities: (params) => httpClient.get(NATIONALITIES_URL, { params }),
	getGenders: () => httpClient.get(GENDER_URL),
	getPayrolls: (data) => httpClient.get(PAYROLLS_URL, { data }),
	getRooms: (params) =>
		httpClient.get(ROOMS_URL, {
			params: { ...params, page: 1, page_size: 100 },
		}),
	getSubject: (params) =>
		httpClient.get(SUBJECT_URL, {
			params: { ...params, page: 1, page_size: 100 },
		}),
	getSchoolLogo: () => httpClient.get(GET_APP_LOGO),
	getStudyYearsApi: () =>
		httpClient.get(GET_APP_STUDY_YEARS, {
			params: { format: 'json', page: 1, page_size: 100 },
		}),
	getQuartersApi: (data) => httpClient.get(GET_QUARTERS_LIST, { params: data }),
	getCurrentSchoolApi: () => httpClient.get(GET_CURRENT_SCHOOL),
	getMarkingSystemsApi: () => httpClient.get(GET_MARKING_SYSTEMS),
};
export default SessionApi;
