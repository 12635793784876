export const INIT_APP = 'INIT_APP';
export const SET_LOGIN_TYPE = 'SET_LOGIN_TYPE';
export const UPDATE_TOKEN = 'UPDATE_TOKEN';
export const SAVE_TOKEN = 'SAVE_TOKEN';
export const CLEAR_TOKEN = 'CLEAR_TOKEN';
export const SAVE_LOGIN_FORM = 'SAVE_LOGIN_FORM';
export const SAVE_REGISTER_FORM = 'SAVE_REGISTER_FORM';
export const VALIDATE_REFRESH_TOKEN = 'VALIDATE_REFRESH_TOKEN';
export const LOGIN_START = 'LOGIN_START';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const SET_ROLE = 'AUTH/SET_ROLE';
