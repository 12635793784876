import { DELETE, update } from 'immupdate';
import { isEmpty, map, noop } from 'lodash';
import { createContext, useContext, useState } from 'react';

import { Message } from '../components/Message';

function createContentValue() {
	return {
		stopLoader: noop,
		startLoader: noop,
	};
}

export const LoaderContext = createContext(createContentValue());

export function LoaderProvider({ children, ...props }) {
	const [loaders, setLoaders] = useState({});
	const [messages, setMessages] = useState({});

	const value = {
		startLoader: (name) => setLoaders((x) => update(x, { [name]: true })),
		stopLoader: (name, message) => {
			if (message) {
				setMessages((x) => update(x, { [name]: message }));
			}

			setLoaders((x) => update(x, { [name]: DELETE }));
		},
	};

	const hideHandler = (name) => setMessages((x) => update(x, { [name]: DELETE }));

	return (
		<LoaderContext.Provider {...props} value={value}>
			{children}

			{!isEmpty(loaders) && (
				<div className="loader-wrapper">
					<div className="loader">Loading...</div>
				</div>
			)}

			{map(messages, (x, idx) => (
				<Message key={idx} message={x} onHide={() => hideHandler(idx)} />
			))}
		</LoaderContext.Provider>
	);
}

export function useLoader() {
	return useContext(LoaderContext);
}
